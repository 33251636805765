const LocalePlugin = {
  dictionary: {
    // HOME:

    mainText: {
      es: `Portuscopia es el portal que da acceso a la visualización y descarga de los datos océano- meteorológicos almacenados 
      por Puertos del Estado. Aquí encontrarás datos históricos obtenidos a partir de los sistemas de monitorización del medio 
      marino de Puertos del Estado, tanto observaciones de las redes de medida como datos obtenidos con modelos de predicción. 
      Esta herramienta está orientada al acceso a grandes volúmenes de datos, complementando y enlazando en ocasiones con el 
      servicio `,
      en: `Portuscopia is the portal that provides access to the visualization and download services
      of the oceanographical and meteorological data stored by Puertos del Estado. Here you
      will find the historical data provided by Puertos del Estado monitoring systems, from
      in-situ measurements to numerical modelling forecasting services. This tool is oriented to the access to large volumes 
      of data, complementing and sometimes linking with the service `,
    },
    menuOptHow: {
      es: "Funcionamiento",
      en: "How it Works",
    },
    menuOptUseCases: {
      es: "Casos de Uso",
      en: "Use Cases",
    },
    menuOptContact: {
      es: "Contacto",
      en: "Contact",
    },
    buttonDownload: {
      es: "Descargar Datos",
      en: "Download Data",
    },
    welcomeMessage: {
      es: "Bienvenido/a a",
      en: "Welcome to",
    },

    textPredicciones: {
      es: `Acceso a las salidas de los servicios de predicción de oleaje, nivel del mar y circulación
      (corrientes, salinidad y temperatura del agua) que diariamente generan resultados de las
      condiciones previstas para los próximos días.`,
      en: `Access to the output fields provided by the forecasting services: waves, sea level and
      circulation (currents, salinity, and sea temperature). 3-day predictions are daily generated.`
    },
    textTiempoReal: {
      es: `Acceso a las series históricas de los datos procedentes de los sistemas de medida del medio
      físico marino de Puertos del Estado: boyas, mareógrafos, estaciones meteorológicas y radares
      HF.`,
      en: `Access to the historical data from Puertos del Estado in-situ measuring stations: buoys, tide-
      gauges, metorological stations and HF radars.`
    },
    textVisualizacionDatos: {
      es: `La navegación por los catálogos de datos es totalmente visual y permite la visualización directa
      de los datos mediante un servicio de mapas integrado.`,
      en: `Navigation through data catalogues is visual, including a web map service that allows direct
      visualization of data.`
    },
    textBusquedaOptimizada: {
      es: `La activación de filtros permite agilizar la navegación entre los catálogos y optimizar la
      búsqueda de datos.`,
      en: `Use filters to optimize the navigation through the catalogues and improve data search.`
    },
    textBusquedaCatalogos: {
      es: `En el menú de búsqueda utilice los filtros que le permitirán ordenar y acotar la búsqueda e
      identificar de forma rápida el conjunto de datos de su interés.`,
      en: `Use filters in the search menu to sort your search criteria and quickly identify dataset of your
      interest.`
    },
    textFiltradoDatos: {
      es: `Acote su búsqueda indicando el rango de fechas, los parámetros y el área en la que se desea
      obtener/visualizar datos.`,
      en: `Narrow your search: select a specific date range, parameters and the geographical area to
      download/visualize data.`
    },
    textDescarga1Click: {
      es: `Existen dos modalidades de descarga: en 1-click para descargas puntuales y recurrente de
      últimos datos mediante un script para descargas automáticas.`,
      en: `There are 2 types of downloads: 1-click for one-time downloads and recurrent, using a script,
      for automated downloads.`
    },
    textUseCase1: {
      es: `La navegación por los catálogos de datos es totalmente visual. En el panel de búsqueda
      (izquierda), utilice los filtros que le permitirán ordenar y acotar la búsqueda e identificar de
      forma rápida el conjunto de datos de su interés. La lista de resultados se mostrará en el panel
      derecho.`,
      en: `Navigation through data catalogues is visual. Use filters in the search panel (left) to sort your
      search criteria and quickly identify the dataset of your interest. The list of results will be
      displayed on the right-side panel.`
    },
    textUseCase2: {
      es: `Mediante un servicio de visualización web de mapas se pueden visualizar directa e
      interactivamente de los datos seleccionados. Sobre estos mapas los usuarios también pueden
      seleccionar áreas y modificar el nivel de zoom.`,
      en: `By means of an integrated web map service, Portuscopia allows direct and interactive
      visualization of data. Users can also select areas as well as zoom-in and zoom-out on
      mentioned data maps.`
    },
    textUseCase3: {
      es: `Los usuarios pueden delimitar el área geográfica de interés para la descarga. Haciendo esto
      solo se descargarán los datos dentro de esa región acotada. La selección se puede hacer
      directamente sobre el mapa donde se visualizan los datos, dibujando el área rectangular con el
      ratón. Si se necesita más precisión, también se puede usar el filtro por coordenadas exactas
      longitud-latitud.`,
      en: `Users can select a specific geographical region of interest as part of the data search. Then, the
      downloaded dataset will be restricted to that defined area. The area of interest can be drawn
      dragging the mouse on the map of data. When more precision is needed, a longitude-latitude
      coordinates filter is also available.`
    },
    textUseCase4: {
      es: `El proceso de descarga es inmediato y termina con la descarga directa de los datos
      seleccionados al ordenador del usuario. Para las descargas recurrentes se ha definido un
      procedimiento específico, pinchar aquí
      para más información.`,
      en: `Downloading process ends when the data files are instantly downloaded and saved on user
      PC/server. For automated and recurrent downloads a specific process has been defined, click
      here for more information.`
    },
    footerLocation: {
      es: "Dirección",
      en: "Location"
    },
    footerMoreInfo: {
      es: "Más Información",
      en: "More Info"
    },
    footerConnect: {
      es: "Nuestras redes sociales",
      en: "Our social media"
    },
    footerPhone: {
      es: "Teléfono",
      en: "Phone"
    },

    // APP:

    scriptNotAvailableTitle: {
      es: "Funcionalidad en desarrollo",
      en: "Feature in progress"
    },
    scriptNotAvailableMsg: {
      es: "Disculpe, la funcionalidad de descarga por script se encuentra actualmente en desarrollo y estará disponible pronto. Mientras tanto, puede realizar descargas puntuales de datos.",
      en: "Sorry, the script functionality is currently in development and will be available soon. Meanwhile, you can perform one-off downloads of data."
    },

    step1: {
      es: "Búsqueda de Catálogo",
      en: "Search Catalog",
    },
    step2: {
      es: "Filtrado de Datos",
      en: "Filter Dataset",
    },
    step3: {
      es: "Descarga de Datos",
      en: "Download Data",
    },

    // STEP 1:

    newSearchBtn: {
      es: "Nueva Búsqueda",
      en: "New Search",
    },
    textPlaceholder: {
      es: "Búsqueda de catálogos...",
      en: "Catalogs search by text...",
    },
    titleSearchByArea: {
      es: "Búsqueda por Área",
      en: "Search by Area",
    },
    msgEmptyResult: {
      es: "No se han encontrado resultados para los filtros seleccionados.",
      en: "No results found for the current selected filters.",
    },
    nodeValue_model: {
      es: "Modelos",
      en: "Models",
    },
    nodeValue_measure: {
      es: "Medidas",
      en: "Measurements",
    },
    nodeValue_temp_circ_niv_sal: {
      es: "3D - Temperatura, Circulación, Nivel y Salinidad",
      en: "3D - Temperature, Circulation, Level and Salinity",
    },
    nodeValue_currents: {
      es: "Corrientes",
      en: "Currents",
    },
    nodeValue_temperature: {
      es: "Temperatura",
      en: "Temperature",
    },
    nodeValue_salinity: {
      es: "Salinidad",
      en: "Salinity",
    },
    nodeValue_wave: {
      es: "Oleaje",
      en: "Waves",
    },
    nodeValue_sea_level_model: {
      es: "2D - Nivel del Mar",
      en: "2D - Sea Level",
    },
    nodeValue_sea_level: {
      es: "Nivel del Mar",
      en: "Sea Level",
    },
    nodeValue_wave_long: {
      es: "Onda Larga",
      en: "Long Waves",
    },
    nodeValue_air_temp: {
      es: "Temp. del Aire",
      en: "Aire temp.",
    },
    nodeValue_air_presure: {
      es: "Presión del Aire",
      en: "Air presure",
    },
    nodeValue_agitation: {
      es: "Agitación",
      en: "Agitation",
    },
    nodeValue_wind: {
      es: "Viento",
      en: "Wind",
    },
    nodeValue_scale_coast: {
      es: "Costera",
      en: "Coast",
    },
    nodeValue_scale_port: {
      es: "Portuaria",
      en: "Port",
    },
    nodeValue_scale_regional: {
      es: "Regional",
      en: "Regional",
    },
    nodeValue_scale_global: {
      es: "Gran Escala",
      en: "Global",
    },
    nodeValue_radar: {
      es: "Radares",
      en: "Radars",
    },
    nodeValue_tide_gaude: {
      es: "Mareógrafos",
      en: "Tide Gauges",
    },
    nodeValue_buoy: {
      es: "Boyas",
      en: "Buoys",
    },
    nodeValue_satellite: {
      es: "Satélite",
      en: "Satellite",
    },
    optGroup_dataType: {
      es: "Tipo de Dato",
      en: "Data Type",
    },
    optGroup_device: {
      es: "Dispositivo",
      en: "Device",
    },
    optGroup_variable: {
      es: "Variable",
      en: "Variable",
    },
    optGroup_scale: {
      es: "Escala",
      en: "Scale",
    },
    labelParameters: {
      es: "Parámetros",
      en: "Parameters",
    },
    labelDates: {
      es: "Fechas",
      en: "Dates",
    },
    noDatePreview: {
      es: "Acceda al catálogo para ver el rango temporal",
      en: "Access the catalogue to see the time range"
    },

    // STEP 2:

    downloadType: {
      es: "Tipo de Descarga",
      en: "Download Type",
    },
    optionOneTime: {
      es: "Puntual",
      en: "One Time",
    },
    optionScript: {
      es: "Recurrente",
      en: "Recurrent",
    },
    msgScript: {
      es: "Se proporcionará un script para descarga de los últimos datos",
      en: "A script will be provided to download the latest data"
    },
    optionHourlyMean: {
      es: "Media Horaria",
      en: "Hourly Mean",
    },
    optionDailyMean: {
      es: "Media Diaria",
      en: "Daily Mean",
    },
    optionDailyNoMean: {
      es: "Diaria",
      en: "Mean",
    },
    optionWeeklyMean: {
      es: "Media Semanal",
      en: "Weekly Mean",
    },
    dateRange: {
      es: "Rango Temporal",
      en: "Date Range",
    },
    measureType: {
      es: "Tipo de Medida",
      en: "Measure Type",
    },
    parametros: {
      es: "Parámetros",
      en: "Parameters",
    },
    areaSelection: {
      es: "Selección de Área",
      en: "Area Selection",
    },
    proceedDownloadBtn: {
      es: "Ir a Descarga",
      en: "Proceed to Download",
    },
    openInPortus: {
      es: "Abrir en Portus",
      en: "Open in Portus",
    },
    allParamsLabel: {
      es: "Todos",
      en: "All"
    },
    datos5mAndMore: {
      es: "Datos 1 min, 5 min, horarios y mensuales",
      en: "1-min, 5-min, hourly and monthly data"
    },
    tooltipPaint: {
      es: "Dibujar área de interés",
      en: "Draw area of interest",
    },
    tooltipAdjust: {
      es: "Volver al área por defecto",
      en: "Return to default area",
    },
    tooltipPan: {
      es: "Mover",
      en: "Panning",
    },

    // STEP 3:

    titleDownloadResume: {
      es: "Resumen de la descarga",
      en: "Download resume",
    },
    titleCatalog: {
      es: "Catálogo",
      en: "Catalog",
    },
    last3DaysAvailable: {
      es: "Últimos 3 días disponibles",
      en: "Last 3 days available"
    },
    titleFileList: {
      es: "Lista de archivos",
      en: "File list",
    },
    btnModify: {
      es: "Modificar",
      en: "Change",
    },
    btnBack: {
      es: "Atrás",
      en: "Back",
    },
    seeInOpendap: {
      es: "Abrir en Opendap",
      en: "See in Opendap",
    },
    explicacionDescargas: {
      es:
        "Con el fin de contribuir a un mejor conocimiento del entorno litoral español y  la gestión de las actividades costeras, Puertos del Estado ofrece acceso a la información oceanográfica de sus sistemas de monitorización, previsión y reanálisis. Para poder tramitar una solicitud de datos los usuarios deben facilitar una información básica.",
      en:
        "In order to support a better knowledge of the Spanish coastal environment and the development of coastal activities, Puertos del Estado distributes, free of charge, the oceanographic information provided by its monitoring, forecasting and hindcasting services. Users are asked to fill out the form below to be able to process the request.",
    },
    explicacionDescargasScript: {
      es:
        "Para poder tramitar una solicitud de datos los usuarios deben estar registrado en el sistema. Una vez autenticado, el equipo de Puertos del Estado evaluará su solicitud y le responderá vía email con la aceptación o rechazo de la misma.",
      en:
        "Users are asked to register in our system to be able to process the request. Once you are authenticated, our team will evaluate your request and send you an email with the acceptance or deny.",
    },
    textoLegalDescargas: {
      es: `
            <p style="text-align: justify;">PROTECCI&Oacute;N DE DATOS PERSONALES</p>
            <p style="text-align: justify;">Para utilizar este servicio los usuarios deben proporcionar a Puertos del Estado ciertos datos de car&aacute;cter personal. De conformidad con lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al &nbsp;tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento General de Protecci&oacute;n de Datos - RGPD) as&iacute; como la Ley Org&aacute;nica 3/2018, de 5 de diciembre Se de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales, le informamos de los siguientes extremos:</p>
            <p style="text-align: justify;">- Los datos de car&aacute;cter personal que nos ha suministrado en esta y otras comunicaciones mantenidascon usted ser&aacute;n objeto de tratamiento bajo la responsabilidad de Puertos del Estado.</p>
            <p style="text-align: justify;">- La finalidad del tratamiento es la de gestionar de forma adecuada la prestaci&oacute;n del servicio que nos ha requerido y porque Vd. ha otorgado su consentimiento mediante una clara acci&oacute;n afirmativa. Asimismo, estos datos no ser&aacute;n cedidos a terceros.</p>
            <p style="text-align: justify;">- Los datos solicitados a trav&eacute;s de esta y otras comunicaciones son de suministro obligatorio para la prestaci&oacute;n del servicio. Estos son adecuados, pertinentes y no excesivos con relaci&oacute;n a los fines para los que fueron recabados.</p>
            <p style="text-align: justify;">- Su negativa a suministrar los datos solicitados o la entrega de datos inexactos o incompletos implica la imposibilidad prestarle el servicio.</p>
            <p style="text-align: justify;">- Asimismo, le informamos de la posibilidad de ejercitar los correspondientes derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n y oposici&oacute;n de conformidad ante Puertos el Estado a trav&eacute;s de los siguientes medios:</p>
            <p style="text-align: justify;">Mail de Contacto: webmaster@puertos.es</p>
            <p style="text-align: justify;">Direcci&oacute;n: Puertos del Estado - Avenida del Parten&oacute;n 10, 28042; Madrid, Espa&ntilde;a</p>
            `,
      en: `
            <p style="text-align: justify;">PERSONAL DATA PROTECTION</p>
            <p style="text-align: justify;">We ask users to provide personal information to make use of this service. In accordance with the provisions of the Regulation (EU) 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, repealing Directive 95/46/EC (General Data Protection Regulation &ndash; GDPR) and Constitutional Law 3/2018, of 5 December, on Personal Data Protection and Guarantee of Digital Rights, it is informed as follows:</p>
            <p style="text-align: justify;">- Personal data collected here and by any other means will be processed under the responsibility of Puertos del Estado.</p>
            <p style="text-align: justify;">- Personal data processing activities are carried out for the purpose of providing an adequate performance of the services required and because you have given your consent by means of a clear affirmative action. Likewise, the data will not be ceded to third parties.</p>
            <p style="text-align: justify;">- The personal data provided though this form and other means of communication are necessary to achieve the purposes for which it was requested. They are adequate, relevant and not excessive in relation to the specific, explicit and legitimate purposes for which they were obtained.</p>
            <p style="text-align: justify;">- Not filling in the fields to provide the data requested or giving inaccurate or incomplete information will prevent Puertos del Estado from providing the service in question.</p>
            <p style="text-align: justify;">- At any moment, users will be able free to exercise their right to access, correction, cancellation and refusal of their personal details by contacting Puertos del Estado at:</p>
            <p style="text-align: justify;">Email: webmaster@puertos.es</p>
            <p style="text-align: justify;">Address: Puertos del Estado - Avenida del Parten&oacute;n 10, 28042; Madrid, Espa&ntilde;a</p>
            `,
    },
    explicacionLogin: {
      es:
        "Para descargar datos recurrente, usted necesita estar registrado dentro de Puertos del Estado.",
      en:
        "In order to download recurrent data, you need to be registered in Puertos del Estado",
    },

    selectOneOption: {
      es: "Seleccione uno...",
      en: "Select one...",
    },
    nombreLabel: {
      es: "Nombre",
      en: "Name",
    },
    apellidoLabel: {
      es: "Apellido",
      en: "Surname",
    },
    organizacionLabel: {
      es: "Organización",
      en: "Organization",
    },
    motivoLabel: {
      es: "Motivo",
      en: "Reason",
    },
    propositoLabel: {
      es: "Propósito",
      en: "Purpose",
    },
    condicionesLabel: {
      es: "Acepto las condiciones",
      en: "I accept the conditions",
    },

    submitOnetime: {
      es: "Enviar y Descargar",
      en: "Submit and Download",
    },
    submitRecurrent: {
      es: "Enviar",
      en: "Submit",
    },
    scriptFinishBtn: {
      es: "Finalizar",
      en: "Close",
    },

    userLoginTitle: {
      es: "Login de Usuario",
      en: "User Login"
    },

    userLoginText: {
      es: "¿Necesita una cuenta de Portuscopia? Acceda",
      en: "Do you need a Portuscopia account? Click"
    },

    userLoginTextLink: {
      es: "aquí",
      en: "here",
    },

    downloadProcess: {
      es: "Proceso de descarga",
      en: "Download process"
    },

    downloadingZip: {
      es: "Descargando zip...",
      en: "Downloading zip..."
    },

    downloadComplete: {
      es: "Descarga completada",
      en: "Download complete"
    },

    downloadCancel: {
      es: "Cancelar descarga",
      en: "Cancel download"
    },

    downloadClose: {
      es: "Cerrar",
      en: "Close"
    },

    downloadPreparing: {
      es: "Preparando descarga...",
      en: "Preparing for download..."
    },

    errorSubmitRecurrent: {
      es: "Error de autenticación: el email y/o password no es correcto",
      en: "Authentication error: email or password is not correct",
    },
    errorServerSubmitRecurrent: {
      es: "Error de servidor. Contacte con el administrador.",
      en: "Server error. Contact with the administrator",
    },
    errorSubmitOnetime: {
      es:
        "Error en envío: debe rellenar los datos del formulario y aceptar las condiciones",
      en: "Submit error: form data must be filled and conditions accepted",
    },
    errorNoFile: {
      es: "Error: no se ha encontrado el archivo correspondiente a la fecha: {date}. Seleccione otra fecha de inicio o desplace el reproductor.",
      en: "Error: no file found for the date: {date}. Select a different initial date or move the player"
    },
    errorNoBounds: {
      es: "Aviso: Las variables seleccionadas en el dataset actual no permiten selección por zona.",
      en: "Warning: The variables selected in the current dataset do not allow selection by zone."
    },

    scriptConfirmationTitle: {
      es: "Solicitud Enviada",
      en: "Request Submitted",
    },
    scriptConfirmationMessage: {
      es: `En breve recibirá un correo electrónico para confirmar la recepción de la solicitud.
          Puertos del Estado tratará de procesar la solicitud dentro de un plazo de 24/48 horas en días
          laborales, aunque el plazo se puede dilatar durante los fines de semana. En cuanto la petición
          sea validada por nuestro equipo, nos pondremos en contacto con usted a través de la
          dirección de correo facilitada.`,
      en: `Within a few moments you will receive an email to confirm that we have received
          your request. Puertos del Estado aims to process your request within 1/2 business days,
          however it can take longer at the weekend. Once your request is approved, we will contact
          you again by e-mail.`,
    },

    // Variables

    var_salinity: {
      es: "Salinidad",
      en: "Salinity",
    },
    infoParam_salinity: {
      es: "Salinidad del agua",
      en: "Water salinity",
    },
    var_ssh: {
      es: "Nivel del Mar (SSH)",
      en: "Sea Level (SSH)",
    },
    infoParam_ssh: {
      es: "Nivel del Mar (SSH)",
      en: "Sea Level (SSH)",
    },
    var_temperature: {
      es: "Temperatura",
      en: "Temperature",
    },
    infoParam_temperature: {
      en: "Skin sea surface temperature",
      es: "Temperatura de la superficie del mar",
    },
    var_u: {
      es: "Velocidad de la corriente este",
      en: "Eastward sea water velocity",
    },
    var_v: {
      es: "Velocidad de la corriente norte",
      en: "Northward sea water velocity",
    },
    var_sea_water_velocity: {
      es: "Velocidad de la corriente",
      en: "Current speed",
    },
    infoParam_sea_water_velocity: {
      es: "Velocidad de la corriente (u x v)",
      en: "Sea current velocity (u x v)",
    },
    var_sea_barotropic_velocity: {
      es: "Velocidad barotrópica de la corriente",
      en: "Barotropic current speed",
    },
    infoParam_sea_barotropic_velocity: {
      es: "Velocidad barotrópica de la corriente (ubar x vbar)",
      en: "Sea barotropic current speed (ubar x vbar)",
    },
    var_res: {
      es: "Residuo meteorológico",
      en: "Residual",
    },
    infoParam_res: {
      es: "Residuo debido a la presión atmosfrérica y el viento a alta frecuencia",
      en: "Residual due to air pressure and wind at hign frequency",
    },
    var_ovyt: {
      es: "Transporte eje Y",
      en: "Transport Y component",
    },
    infoParam_ovyt: {
      es: "transporte efectivo a lo largo del eje y",
      en: "Effective ocean transport along y-axis",
    },
    var_ovxt: {
      es: "Transporte eje X",
      en: "Transport X component",
    },
    infoParam_ovxt: {
      es: "transporte efectivo a lo largo del eje x",
      en: "Effective ocean transport along x-axis",
    },
    var_VHM0: {
      es: "Hm0",
      en: "Hm0",
    },
    infoParam_VHM0: {
      es: "Altura significante espectral del oleaje",
      en: "Spectral significant waves height",
    },
    var_VMDR_SW2: {
      es: "Dirección media del mar de fondo secundario",
      en: "Secundary swell mean direction",
    },
    infoParam_VMDR_SW2: {
      es: "Dirección media de procedencia del mar de fondo secundario",
      en: "Mean coming from direction of secundary swell",
    },
    var_VMDR: {
      es: "Dirección media del oleaje",
      en: "Waves mean direction",
    },
    infoParam_VMDR: {
      es: "Dirección media de procedencia del oleaje",
      en: "Mean coming-from direction of waves",
    },
    var_VHM0_SW1: {
      es: "Hm0 del mar de fondo primario",
      en: "Primary swell Hm0",
    },
    infoParam_VHM0_SW1: {
      es: "Altura significante del mar de fondo primario",
      en: "Primary swell significant waves height",
    },
    var_VMDR_SW1: {
      es: "Dirección media del mar de fondo primario",
      en: "Primary swell mean direction",
    },
    infoParam_VMDR_SW1: {
      es: "Dirección media de procedencia del mar de fondo primario",
      en: "Mean coming-from direction of primary swell",
    },
    var_VHM0_WS: {
      es: "Hm0 del mar de viento",
      en: "Wind sea Hm0",
    },
    infoParam_VHM0_WS: {
      es: "Altura significante del mar de viento",
      en: "Wind sea singificant height",
    },
    var_VTPK: {
      es: "Tp",
      en: "Tp",
    },
    infoParam_VTPK: {
      es: "Periodo del oleaje en el pico espectral",
      en: "Waves period at the spectral density maximum",
    },
    var_VMDR_WS: {
      es: "Dirección media del mar de viento",
      en: "Wind sea mean direction",
    },
    infoParam_VMDR_WS: {
      es: "Dirección media de procedencia del mar de viento",
      en: "Coming-from direction of wind sea",
    },
    var_VHM0_SW2: {
      es: "Hm0 del mar de fondo secundario",
      en: "Secondary swell Hm0",
    },
    infoParam_VHM0_SW2: {
      en: "Secondary swell significant waves height",
      es: "Altura significante del mar de fondo secundario",
    },
    var_VSMC_WS: {
      es: "Tm02 del mar de viento",
      en: "Wind sea Tm02",
    },
    infoParam_VSMC_WS: {
      es: "Tm02 del mar de viento",
      en: "Wind sea Tm02",
    },
    var_VSMC: {
      es: "Tm02",
      en: "Tm02",
    },
    infoParam_VSMC: {
      es: "Periodo medio espectral del oleaje",
      en: "Waves second moment spectral mean period",
    },
    var_VSMC_SW1: {
      es: "Tm02 del mar de fondo primario",
      en: "Primary swell Tm02",
    },
    infoParam_VSMC_SW1: {
      es: "Periodo medio espectral del mar de fondo primario",
      en: "Primary swell second moment spectral mean period",
    },
    var_VSMC_SW2: {
      es: "Tm02 del mar de fondo secundario",
      en: "Secondary swell Tm02",
    },
    infoParam_VSMC_SW2: {
      es: "Periodo medio espectral del mar de fondo secundario",
      en: "Secondary swell second moment spectral mean period",
    },

    var_cov: {
      es: "Covarianza",
      en: "Covariance",
    },
    infoParam_cov: {
      es: "Covarianza de la velocidad de la corriente superficial",
      en: "Covariance of surface sea water velocity",
    },
    var_stdu: {
      es: "Desviación estandar U",
      en: "Standard deviation U",
    },
    infoParam_stdu: {
      es: "Desviación estándar de la velocidad zonal de la corriente superficial ",
      en: "Standard deviation of surface eastward sea water velocity",
    },
    var_stdv: {
      es: "Desviación estandar V",
      en: "Standard deviation V",
    },
    infoParam_stdv: {
      es: "Desviación estándar de la velocidad meridional de la corriente superficial",
      en: "Standard deviation of surface nothward sea water velocity",
    },

    var_SLEV: {
      es: "Nivel del mar",
      en: "Sea level",
    },
    infoParam_SLEV: {
      es: "Nivel del mar observado",
      en: "Observed sea level",
    },
    var_SLEV_QC: {
      es: "Flag de calidad de nivel del mar",
      en: "Sea level quality flag",
    },
    infoParam_SLEV_QC: {
      es: "Indicador de calidad del dato observado de nivel del mar",
      en: "Quality flag of observed sea level data",
    },
    var_TIME_QC: {
      es: "Flag de calidad de la fecha",
      en: "Time quality flag",
    },
    infoParam_TIME_QC: {
      es: "Indicador de calidad asociado a la fecha y hora",
      en: "Quality flag of date",
    },
    var_VC_IG: {
      es: "Velocidad máxima de la corriente infragravitatoria",
      en: "Infragravitatory maximum current speed",
    },

    infoParam_VC_IG: {
      es: "Velocidad de la corriente infragravitatoria inferida de la onda",
      en: "Infragravitatory current velocity inferred from wave",
    },
    var_TM02_IG: {
      es: "Tm02 de onda infragravitatoria",
      en: "Infragravitatory Tm02",
    },
    infoParam_TM02_IG: {
      es: "Momentos espectrales infragravitatorios (0,2) Período de onda inferido de espectros de onda",
      en: "Infragravitatory spectral moments (0,2) wave period inferred from wave spectra",
    },
    var_TP_IG: {
      es: "Tp de onda infragravitatoria",
      en: "Infragravitatory Tp",
    },
    infoParam_TP_IG: {
      es: "Período pico de ondas infragravitatorias inferido a partir de espectros de ondas",
      en: "Infragravitatory wave peak period inferred from wave spectra",
    },
    var_HM0_IG: {
      es: "Hm0 infragravitatoria",
      en: "Infragravitatory Hm0",
    },
    infoParam_HM0_IG: {
      es: "Altura de onda significativa espectral infragvitatoria inferida a partir de espectros de onda",
      en: "Infragravitatory spectral significant wave height inferred from wave spectra",
    },


  },

  translate(id, locale) {
    var key = this.getKey(id);
    if (key) {
      var tag = this.dictionary[key];
      if (!tag) return key;
      var text = tag[locale];
      if (!text) return key;
      return text;
    } else {
      return id;
    }
  },

  getKey(text) {
    if (text[0] == "{" && text[text.length - 1] == "}") {
      return text.replace("{", "").replace("}", "");
    } else {
      return null;
    }
  },

  install(Vue) {
    Vue.prototype.$translate = (id, locale) => this.translate(id, locale);
    Vue.$translate = (id, locale) => this.translate(id, locale);
  },
};

export default LocalePlugin;
